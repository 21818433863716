import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthConfigService {
  redirect: string = window.location.origin;

  constructor(private platform: Platform) {}   


  getConfig(): OpenIdConfiguration {
    let redirectUrl = window.location.origin;
    if (this.platform.is('android')) {
      redirectUrl = 'sparo://login'
    }
    if (this.platform.is('ios')) {
      redirectUrl = 'sparo://localhost/login'
    }
    
    return {
      authority: environment.baseUrl,
      redirectUrl: redirectUrl,
      postLogoutRedirectUri: redirectUrl,
      clientId: 'sparoclient',
      scope: 'openid offline_access profile IdentityServerApi',
      responseType: 'code',
      silentRenew: true,
      renewTimeBeforeTokenExpiresInSeconds: 5,
      useRefreshToken: true,
      logLevel: LogLevel.Debug,
      secureRoutes: [environment.baseUrl]
    };
  }
}