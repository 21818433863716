import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from './services/languageservice/language.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {
  AbstractSecurityStorage,
  AuthInterceptor,
  AuthModule,
  OidcSecurityService,
  StsConfigLoader,
  StsConfigStaticLoader,
} from 'angular-auth-oidc-client';
import { AuthConfigService } from './services/authConfig/auth-config.service';
import { ApiService } from './services/api-services/api.service';

import { AuthStorageService } from './services/authStorage/auth-storage.service';
import { ConditionalModalComponent } from './modals/conditional-modal/conditional-modal.component';
import { registerLocaleData } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { SearchService } from './shared/search/search.service';
import { AttendeesModalComponent } from './modals/attendees-modal/attendees-modal.component';
import { HttpCancleService } from './services/httpCancelService/http-cancle.service';
import { ManageHttpInterceptor } from './interceptors/manageHttp-interceptor.interceptor';
import { catchError, map, of } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { AddFavoritesModalComponent } from './modals/add-favorites-modal/add-favorites-modal.component';
import { BackService } from './services/back-service/back.service';
import { DatetimeFormatService } from './services/datetime-format-service/datetime-format.service';
const authFactory = (configService: AuthConfigService) => {
  const config = configService.getConfig();
  return new StsConfigStaticLoader(config);
};

// Import locale data for all required locales
import localeEnUS from '@angular/common/locales/en';
import localeEnGB from '@angular/common/locales/en-GB';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeZh from '@angular/common/locales/zh';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeRu from '@angular/common/locales/ru';
import localePt from '@angular/common/locales/pt';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AddFavoriteMapsModalComponent } from './modals/add-favorite-maps-modal/add-favorite-maps-modal.component';

// Register locale data
registerLocaleData(localeEnUS, 'en-US');
registerLocaleData(localeEnGB, 'en-GB');
registerLocaleData(localeDe, 'de-DE');
registerLocaleData(localeFr, 'fr-FR');
registerLocaleData(localeEs, 'es-ES');
registerLocaleData(localeZh, 'zh-CN');
registerLocaleData(localeJa, 'ja-JP');
registerLocaleData(localeKo, 'ko-KR');
registerLocaleData(localeRu, 'ru-RU');
registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent,
    ConditionalModalComponent,
    AttendeesModalComponent,
    AddFavoritesModalComponent,
    AddFavoriteMapsModalComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ swipeBackEnabled: false, mode: 'ios' }),
    AppRoutingModule,

    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authFactory,
        deps: [AuthConfigService],
      },
    }),
    SharedModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SearchService,
    LanguageService,
    DatetimeFormatService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ls: LanguageService) => () => {
        return ls.initializeDeviceLanguage();
      },
      deps: [LanguageService, TranslateService],
      multi: true,
    },
    AuthConfigService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ManageHttpInterceptor,
      multi: true,
    },
    HttpCancleService,
    ApiService,
    { provide: AbstractSecurityStorage, useClass: AuthStorageService },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    OidcSecurityService,
    {
      provide: APP_INITIALIZER,
      useFactory: (service: OidcSecurityService) =>
        function () {
          if (Capacitor.isNativePlatform()) {
            return service.forceRefreshSession().pipe(
              catchError((error) => {
                return of(true);
              })
            );
          }
        },
      deps: [OidcSecurityService],
      multi: true,
    },
    BackService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
