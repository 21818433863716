// managehttp.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router, ActivationEnd } from '@angular/router';
import { HttpCancleService } from '../services/httpCancelService/http-cancle.service';
import { catchError, map, retry, switchMap, takeUntil } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Preferences } from '@capacitor/preferences';
import { error } from 'console';
import { request } from 'http';

const TOKEN_HEADER_KEY = 'Authorization'
@Injectable()
export class ManageHttpInterceptor implements HttpInterceptor {

  isAuthenticated: boolean;
  
  constructor(
    private router: Router,
    private httpCancelService: HttpCancleService, 
    private oidcSecurityService: OidcSecurityService) 
    {
          this.oidcSecurityService.isAuthenticated$.subscribe(status => {
            this.isAuthenticated = status.isAuthenticated;
          });
          router.events.subscribe(event => {
                // An event triggered at the end of the activation part of the Resolve phase of routing.
                if (event instanceof ActivationEnd) {
                  // Cancel pending calls
                  this.httpCancelService.cancelPendingRequests();
                }
          });
    }

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
      
      let request = req;
      let token = new BehaviorSubject<string>("");

      if (keepSystemUrlsAlive(req)) 
      {
        return next.handle(request);
      }

      if (keepApplicationUrlsAlive(req))  
      {
        return this.oidcSecurityService.getAccessToken().pipe(switchMap(token => {
          request = this.addTokenHeader(req, token);

          return next.handle(request).pipe(catchError(error => {
            if(error.status == 401){
              throw this.RefreshSessionIfRefreshtoken(request, next)
            }
            throw (error)
          }));
        }))
      }
      else 
      {
        return this.oidcSecurityService.getAccessToken().pipe(switchMap(token => {
          request = this.addTokenHeader(req, token);
          return next.handle(request).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()), 
          catchError(error => {
            if(error.status == 401){
              throw this.RefreshSessionIfRefreshtoken(request, next)
            }
            throw (error)
          }));
        }))
      }
    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
      /* for Spring Boot back-end */
      // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
  
      /* for Node.js Express back-end */
      
      return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    }

    private RefreshSessionIfRefreshtoken(req,next?) {
      return this.oidcSecurityService.getRefreshToken().pipe(
         switchMap(refreshToken => {
           if (refreshToken) {
             return this.oidcSecurityService.forceRefreshSession().pipe(
               switchMap( isAuthenticated  => {
                if (isAuthenticated != null) {
                  let request = this.addTokenHeader(req ,isAuthenticated.accessToken)
                    
                  return next.handle(request);
                } else {
                  this.oidcSecurityService.checkAuth().subscribe(isAuthenticated => {
                    if (isAuthenticated.isAuthenticated) {
                      let request = this.addTokenHeader(req ,isAuthenticated.accessToken)
                        
                      return next.handle(request);
                    }
                  })
                }

               }),
               catchError(err => {throw (err);})
             );
           }
         })
       )
     }
}

function keepSystemUrlsAlive<T>(request: HttpRequest<T>) {
  return request.url.includes("token") || request.url.includes("jwks") || request.url.includes("i18n") || request.url.includes("openid-configuration");
}

function keepApplicationUrlsAlive<T>(request: HttpRequest<T>) {
  return request.url.includes("me") || request.url.includes("userinfo") || request.url.includes("attendees") || request.url.includes("userSettings") || request.url.includes("avatars") || request.url.includes("invitaionEventsCount") || request.url.includes("floorplan/info")
}
