// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --border-color: var(--ion-color-danger);
  --border-width: 0 0 3px 0;
}

.icon-hide {
  opacity: 0;
}

.add-favorite-icon {
  font-size: 28px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/header/header.component.scss"],"names":[],"mappings":"AAAA;EACI,uCAAA;EACA,yBAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":["ion-toolbar {\n    --border-color: var(--ion-color-danger);\n    --border-width: 0 0 3px 0;\n}\n\n.icon-hide {\n    opacity: 0;\n}\n\n.add-favorite-icon{\n    font-size: 28px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
