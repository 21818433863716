import { Component, NgZone, OnInit,ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { App, URLOpenListener, URLOpenListenerEvent } from '@capacitor/app';
import { ActionSheetController, ActionSheetOptions, NavController, Platform, ToastController } from '@ionic/angular';
import { AuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { Browser, OpenOptions } from '@capacitor/browser';

import { ApiService } from './services/api-services/api.service';
import { SettingsPage } from './settings/settings.page';
import { StatusBar, Style } from '@capacitor/status-bar';
import { FeedbackService } from './services/feedback-service/feedback.service';
import { PublicEventsService } from 'angular-auth-oidc-client';
import { Preferences } from '@capacitor/preferences';
import { catchError, filter, finalize, map, of, switchMap } from 'rxjs';
import { SplashScreen } from '@capacitor/splash-screen';
import { BackService } from './services/back-service/back.service';
import { LanguageService } from './services/languageservice/language.service';
import { WebSocketService } from './services/web-socket.service';
import { register } from 'swiper/element/bundle';

const setStatusBarStyleDark = async () => {
  await StatusBar.setStyle({ style: Style.Dark });
  };

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  

  SettingsComp = SettingsPage
  // public appPages = [
  //    { title: 'Home', icon: 'calendar-sharp', route: '/tabs/home', url: null, disabled: false },
  //    { title: 'MyProfile', icon: 'people-circle-outline', route: '/my-profile', disabled: false },
  //    { title: 'Help', icon: 'help-circle', route: '/', url: null, disabled: true },
  //    { title: 'Rate Us', icon: 'star', route: '/', disabled: true },
  //    { title: 'Support', icon: 'chatbubbles-sharp', route: '/support', disabled: true },

  //    { title: 'Settings', icon: 'settings', route: '/settings', disabled: true },
  // ];
  public appPages = [
    //{ title: 'Home', icon: 'calendar-sharp', route: '/tabs/home', url: null, disabled: false },
    { title: 'myProfile', icon: 'people-circle-outline', route: '/my-profile', disabled: false },
    { title: 'firstAid', icon: 'fitness', route: '/first-aid', disabled: false },
    { title: 'settings', icon: 'settings', route: '/settings', disabled: true },
    { title: 'support', icon: 'chatbubbles-sharp', route: '/support', disabled: true },
 ];
  isLoading: boolean;
  isNoUser:boolean;
  constructor(
    private platform: Platform,
    private oidcSecurityService: OidcSecurityService,
    private zone: NgZone,
    private router: Router,
    private navigation: NavController,
    public apiService: ApiService,
    private feedbackService: FeedbackService,
    private eventService: PublicEventsService,
    private language: LanguageService,
    private backService: BackService,
    private toastController: ToastController,
    private actionSheetController: ActionSheetController,
    private webSocket: WebSocketService,
    private changeDetectorRef:ChangeDetectorRef) {
    this.webSocket.loading$.subscribe(loading=> {
      //we can use markForCheck or detect changes 
      this.zone.run(() => this.changeDetectorRef.detectChanges());
    })
    this.initializeApp();
    this.initializeBackButtonHandler();
    register();
    this.eventService
    .registerForEvents()
    .subscribe((value) => {
      if (value.type === 2) {
        Preferences.set({
          key: 'isLoggedin',
          value: (false).toString()
        }) 
      } 
    });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
  ).subscribe((event: NavigationEnd) => {
    // one of main tabs => resetting into initial state
    if(event.url.includes('/tabs')){
      backService.navigationUrls = backService.navigationUrls.length > 1 ? [backService.navigationUrls[backService.navigationUrls.length -1], event.url] : [event.url]
      backService.navigationDepth = backService.navigationUrls.length > 1 ?  1 : 0;
      //after login the favorite url not getting stored and 2 elements getting stored ['/login, '/']
    }else if(event.url.includes('login') || event.url == '/'){
      backService.navigationUrls= ['/tabs/favorite']
      backService.navigationDepth= 0;
    }
    else{
      if(backService.navigationUrls.find(url=> url == event.url) != undefined) {
        backService.navigationUrls.splice(backService.navigationUrls.indexOf(event.url),1)
        backService.navigationUrls.push(event.url);
      } else {
        backService.navigationUrls.push(event.url);
        ++backService.navigationDepth;
      }
    }
  });
  }

  initializeApp() {
    if(localStorage.getItem("selectedlanguage")){
      this.language.selectLanguage(JSON.parse(localStorage.getItem("selectedlanguage")))
    }
    this.platform.ready().then(() => {
      // this.toastController.create({ animated: false }).then(t => { t.present(); t.dismiss(); });
      // this.actionSheetController.create({ buttons:[], animated:false, translucent:false}).then(t => { t.present(); t.dismiss(); });
      SplashScreen.hide();
      if (this.platform.is('android') || this.platform.is('ios')) {
        setStatusBarStyleDark();
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
          this.zone.run(() => {
            this.apiService.LogInLoading.next(true);
            this.oidcSecurityService.checkAuth(event.url).subscribe( isAuthenticated  => {
              console.log("check auth", isAuthenticated);
              
              Browser.close();
              if (isAuthenticated.isAuthenticated) {
                this.router.navigateByUrl('tabs/favorite').then(() => {
                  this.apiService.LogInLoading.next(false);
                });
                
              } else {
                // let queryParams = event.url.split("?")[1].split("=")[0];
                let queryParams = event.url.split("?")[1];
                let url = "login?" + queryParams;
                this.router.navigateByUrl(url).then(() => this.apiService.LogInLoading.next(false)
              );
              }
            });
          });
        });
      }else {
        this.oidcSecurityService.checkAuth().subscribe();
      }
    });

    this.platform.resume.subscribe(result => {
      this.isLoading = true;
      this.oidcSecurityService.isAuthenticated$.subscribe( isAuthenticated  => {                
        if (!isAuthenticated.isAuthenticated) {
          this.RefreshSessionIfRefreshtoken();
        }else {
          this.isLoading = false;
        }
      });
    })
    this.feedbackService.checkBookingCount().subscribe()
  }
  
  initializeBackButtonHandler() {
    this.platform.backButton.subscribeWithPriority(10, () => {
    });
    this.platform.ready().then(() => {
      if (this.platform.is('android')) {
        App.addListener('backButton', (event) => {
          if(event.canGoBack && !this.router.url.includes('/login')) this.handleBackButton();
        });
      }
    });
  }

  handleBackButton() {
    if (!this.router.url.includes('/tabs')) {
      if(!this.router.url.includes('login') && this.router.url != '/')
      { 
        this.backService.goBackToPrevious();
      }
    } 
  }

  open(route) {
    this.navigation.navigateForward(route)
  }

  async openInappBrowser(url: string) {
    await Browser.open({ url });
  }

  logout() {
    if (this.platform.is('capacitor')) {
      const authOption: AuthOptions = {
        urlHandler: async (authUrl) => {
          await Browser.open({ url: authUrl });
        },
      };
      this.oidcSecurityService
        .logoff(undefined, authOption)
        .subscribe((r) => {
        console.log('Logged out');
        sessionStorage.removeItem("sessionOnboarding")
        });
    } else {
      const redirectUrl = window.location.origin;
      const logoutAuthOptions: AuthOptions = {
        customParams: {
          logout_hint: redirectUrl,
        },
      };
      this.oidcSecurityService
        .logoff(undefined, logoutAuthOptions)
        .subscribe((r) => {
          console.log('Logged out');
          sessionStorage.removeItem("sessionOnboarding")
          });

      window.location.href = redirectUrl;
    }
  }

  private RefreshSessionIfRefreshtoken() {

    return this.oidcSecurityService.getRefreshToken().pipe(catchError(error => {
      return of(false);
     })).subscribe(
       refreshToken => {
         if (refreshToken) {
           return this.oidcSecurityService.forceRefreshSession().pipe(catchError(error => {
            let AuthResult = {
              isAuthenticated: false
            }
            return of(AuthResult);
           })).subscribe(AuthResult => {
            this.isLoading = false
            if (AuthResult.isAuthenticated) {
              this.router.navigateByUrl('tabs/favorite');
            } else {              
              this.router.navigateByUrl('unauthorized');
            }
           })
         }else {          
          this.isLoading = false
          if (!this.router.url.includes('login')) {
            this.router.navigateByUrl('unauthorized');
          }
        }    
       })
     
   }



}

