/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { finalize, lastValueFrom, map, mergeMap, Observable, of, switchMap, take, tap } from 'rxjs';
import { ApiService } from '../services/api-services/api.service';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private oidcSecurityService: OidcSecurityService, private router: Router) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.oidcSecurityService.getRefreshToken().pipe(
      map(refreshToken => {
        if(refreshToken) {
          return true
        }
        return this.router.parseUrl('/login')
      }));
  }

}
