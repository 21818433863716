export const DATETIMEFORMART = 'YYYY-MM-DDTHH:mm';

export interface EventRequest {
  placeEmailAddress?: string;
  subject: string;
  startTime: string;
  endTime: string;
  reminderMinutesBeforeStart?: number | null;
  attendees?: AttendeeInformation[];
  isOnPrem: boolean;
  showAs?: string;
  recurrencePattern? : RecurrencePattern
  timeZoneInfo?: string
}

export interface CalendarEvent {
  id: string;
  startTime: string;
  endTime: string;
  subject: string;
  isOrganizer: boolean;
  showAs?: string;
  reminderMinutesBeforeStart?: number | null;
  place: {
    address: {
      street: string;
      zip: string;
      city: string;
      floor?: string;
      country:string;
      floorNumber:Number
    };
    displayName: string;
    type: string;
    emailAddress?: string;
    avatar?: string;
    capacity:any;
  };
  resourceStatus: string;
  attendees: AttendeeInformation[];
  organizer: OrganizerInformation;
  seriesMasterId: string;
  isSeriesActionLoding:boolean;
  recurrencePattern: RecurrencePattern;
  isNewEvent:boolean;
  editMasterEvent:boolean
}
export interface RecurrencePattern {
  startTime: Date;
  endTime: Date;
  startDate: Date;
  endDate: Date;
  accuranceDays: string[];
  interval: number;
  accuranceType: string;
  dayOfMonth?: number;
  month?: number;
  index?: string;
}
export interface Place {
  type: string;
  displayName: string;
  avatar: any;
  isAvailable: boolean;
  capacity?: string;
  availableSpace?: string;
  emailAddress: string;
  isFav: boolean;
  favIndexOrdering: number;
  address: {
    country: any;
    street: string;
    zip: string;
    city: string;
    floor?: string;
    floorNumber:number;
  };
  isOnPrem: boolean;
}

export interface SearchRequest {
  startTimeUTC: string;
  endTimeUTC: string;
  type?: string;
  localStartTimeValue?: any;
  localEndTimeValue?: any;
  isAllDay?: boolean;
  recurrenceType?:string;
  repeatEvery?:number;
  recurrenceEndDate?:string;
  recurrenceStartDate?:string;
  dayOfMonthlyMeeting?:number;
  selectedWeekDays?:number[]
}


export interface UserInformation {
  avatar: string;
  displayName: string;
  jobTitle: string;
  department: string;
  companyName: string;
  surname: string;
  givenName: string;
  mobilePhone: string;
  userPrincipalName: string;
  licenseExperation: string;
  workingHours: WorkingHours;
  vehicleNumber: string;
}
export interface WorkingHours {
  daysOfWeek:number[],
  startTime:{
    hour:number,
    minute:number,
  }
  endTime:{
    hour:number,
    minute:number,
  }
}

export interface AttendeeInformation {
  firstName: string;
  lastName: string;
  userPrincipalName: string;
  isOptional?: boolean;
  isAvailable?: boolean;
  response: string;
}

// export interface AttendeeResponseInformation{
//     displayName: string;
//     userPrincipalName: string;
//     isOptional?: boolean;
//     response: string;
// }

export interface BookingPeriod {
  startTime: string;
  selectedStartTime: string;
  endTime: string;
  selectedEndTime: string;
  startTimeMinutes: number;
  endTimeMinutes: number;
  selectedTimesRange: any;
}

export interface FloorPlanInfo {
  country: string;
  city: string;
  street: string;
  floor: number;
  floorLabel: string;
  _id: string;
  floorMapImage: any;
  lastUpdated: string;
  organisationId: string;
  triggerringUPN ?:string;
}

export interface OrganizerInformation {
  name: string;
  emailAddress: string;
}

export interface FeedbackInterface {
  age: string;
  bookingFlow: string;
  comment: string;
  design: string;
  filteringFlow: string;
  os: string;
  overall: string;
  profession: string;
  rating: string;
  suggestion: string;
  usability: string;
  creationDate: string;
  version: string;
  appName: string;
}
/*export interface AccountContactTicket {
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
    phoneNumber: string;
    language: string
}*/
export interface UserPreferences {
  userSettings: UserSettings;
  userFavoriteMaps: string[];
}
export interface UserSettings {
  bookingStatus: string;
}

export interface PlaceAvatar{
  upn: string;
  avatar: string;
}
export interface PlaceAvatarStorage{
  avatars: PlaceAvatar[];
  savedAt: string;
}

export interface OnboardingSlide {
  image: string;
  header: string;
  discription: Array<string>;
}

export const reminderMinutes = [
  { text: 'reminderSelect.without', value: null },
  { text: 'reminderSelect.atEventStart', value: 0 },
  { text: 'reminderSelect.fiveMinutes', value: 5 },
  { text: 'reminderSelect.tenMinutes', value: 10 },
  { text: 'reminderSelect.fithteenMinutes', value: 15 },
  { text: 'reminderSelect.thirtyMinutes', value: 30 },
  { text: 'reminderSelect.oneHour', value: 60 },
  { text: 'reminderSelect.twoHours', value: 120 },
  { text: 'reminderSelect.oneDay', value: 1440 },
  { text: 'reminderSelect.oneWeek', value: 241920 },
];

export const showAsOptions = [
  { text: 'showAsOptions.Free', value: 'Free', index: 0 },
  {
    text: 'showAsOptions.WorkingElsewhere',
    value: 'WorkingElsewhere',
    index: 4,
  },
  { text: 'showAsOptions.Tentative', value: 'Tentative', index: 1 },
  { text: 'showAsOptions.Busy', value: 'Busy', index: 2 },
  { text: 'showAsOptions.OutOfOffice', value: 'Oof', index: 3 },
];